import React from "react"
import {AppRoot, NotFoundPage, Seo} from "components"

function NotFound(props) {
  return (
    <>
      <Seo title="404 - Not Found" />
      <AppRoot page={NotFoundPage} {...props} />
    </>
  )
}

export default NotFound
